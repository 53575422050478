/* import PromoImage1 from "../assets/images/promo_1.png"; */
import PopupHeroTransparentImg from "../assets/images/popup-hero-transparent.png";

/* export const POPUP_DATA = {
  title:
    "Have you seen our Energy Efficient Home (EEH) cashback products?\nCashback incentives range from £750 - £1,750",
  description:
    "some foot note some foot note some foot note some foot note some foot note some foot note some foot note",
  image: PromoImage1,
  variant: "promo1",
  link: "/energy-efficient-home"
}; */

export const POPUP_DATA = {
    title:"",
    description:
    "Whether you’re a broker, dedicated administrator or are a self-employed adviser who manages their own admin, this webinar has been created with you in mind, providing you with the tools you need to work more efficiently, and better serve your customers.",
    image: PopupHeroTransparentImg,
    variant: "promo2",
    link: "/packaging-webinars",
    name: "Packiging webinars popup",
    titleAboveImage: "Want a quicker 'Application to Offer' time?",
    titleBelowImage: "Register for one of our webinars to find out more.",
  };

/* eslint-disable no-unused-vars */
import { MenuProvider } from "./src/context";
import React, { useEffect, useState } from "react";
import "@webcomponents/shadydom";
import "wicg-inert";
import "details-element-polyfill";
import Popup from "./src/components/pop-up/pop-up.component";
import { POPUP_DATA } from "./src/data/popup.data";

const CONSENT_COOKIE_NAME = "CONSENTMGR-UK-RBWM-CG1";

const checkIfCookiesExist = (cookieNames) => {
  const allCookies = document.cookie.split("; ");

  return allCookies.some((cookie) => cookie.startsWith(`${cookieNames}=`));
};

//Need to change "isVisited" to some other localStorage key when implementing next popup
//to force the first show across all sessions (example "isAdvBannerShown")
const checkFirstVisit = () => {
  const hasVisited = localStorage.getItem("isVisited");

  if (!hasVisited) {
    localStorage.setItem("isVisited", "true");

    return true;
  }

  return false;
};

const RootElementWrapper = ({ children }) => {
  /* const [cookieExists, setCookieExists] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);

  useEffect(() => {
    const isConsentSet = checkIfCookiesExist(CONSENT_COOKIE_NAME);

    if (isConsentSet) {
      setCookieExists(true);
    } else {
      const interval = setInterval(() => {
        const isUpdatedConsentSet = checkIfCookiesExist(CONSENT_COOKIE_NAME);

        if (isUpdatedConsentSet) {
          setCookieExists(true);
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (cookieExists && checkFirstVisit()) {
      setPopupData({
        title: POPUP_DATA.title,
        description: POPUP_DATA.description,
        img: POPUP_DATA.image,
        link: POPUP_DATA.link,
        titleAboveImg: POPUP_DATA.titleAboveImage,
        titleBelowImg: POPUP_DATA.titleBelowImage
      });
      setShowPopup(true);
    }
  }, [cookieExists]); */
 
  return (
    <>
      {children}
      {/* {showPopup && <Popup data={popupData} variant={POPUP_DATA.variant} shouldOpen={true} />} */}
    </>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <MenuProvider>
      <RootElementWrapper>{element}</RootElementWrapper>
    </MenuProvider>
  );
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window !== "undefined") {
    window.previousPath = prevLocation ? prevLocation.href : "";
  }
};

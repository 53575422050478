import React, { useContext } from "react";
import { MenuContext } from "../../context";

const FindOutMoreBtn = ({ additionalClass, goTo, buttonText }) => {
  const menuContext = useContext(MenuContext);
  return (
    <section className={`find-out-more-btn ${additionalClass || ""}`}>
      <a href={goTo} target="_self" inert={menuContext.isMenuOpen ? "true" : null}>
        {buttonText ? buttonText : "Find out more"}
      </a>
    </section>
  );
};

export default FindOutMoreBtn;

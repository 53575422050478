import React from 'react';

const defaultState = {
  isMenuOpen: false,
  toggleMenu: () => {
  },
};

const MenuContext = React.createContext(defaultState);

class MenuProvider extends React.Component {
  state = {
    isMenuOpen: false,
  };

  toggleMenu = () => {
    this.setState(
      state => ({ isMenuOpen: !state.isMenuOpen }),
    );
  };

  render() {
    const { children } = this.props;
    const { isMenuOpen } = this.state;
    return (
      <MenuContext.Provider
        value={{
          isMenuOpen,
          toggleMenu: this.toggleMenu,
        }}
      >
        {children}
      </MenuContext.Provider>
    );
  }
}

export { MenuContext };

export { MenuProvider };
import React, { useState, useEffect, useRef } from "react";
import FindOutMoreBtn from "../find-out-more-btn/find-out-more-btn";

export default function Popup({ data, variant, biopage, shouldOpen = false }) {
  const [isOpen, setIsOpen] = useState(shouldOpen);
  const popupRef = useRef(null);

  useEffect(() => {
    if (data && (data.name || data.description)) {
      setIsOpen(true);
    }
  }, [data]);

  const closePopup = (event) => {
    event.preventDefault();
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      const popupEl = popupRef?.current;
      const focusableElements = popupEl?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      const handleTabKeyPress = (event) => {
        if (event.key === "Tab") {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      };

      firstElement.focus();

      popupEl.addEventListener("keydown", handleTabKeyPress);
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        popupEl.removeEventListener("keydown", handleTabKeyPress);
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isOpen]);

  if (!isOpen || !data) return null;
  const modifiedName = data.title.toLowerCase().replace(/\s+/g, "-");

  const collageContent = (
    <>
      <img src={data.img} alt={data.name} />
      <div className="profile__title">
        <p>
          <strong>{data.name}</strong>
        </p>
        <p>{data.title}</p>
      </div>
    </>
  );

  const promoVariant1 = (
    <>
      <div className="popup__content">
        {data.title.split("\n").map((text, index) => (
          <h2 key={index}>
            {text}
            <br />
          </h2>
        ))}
        {data?.link && <FindOutMoreBtn additionalClass={"popup__button"} goTo={data.link} />}
        <p>{data.description}</p>
      </div>
      <div className="popup__image">
        <img src={data.img} alt={data.name} />
      </div>
    </>
  );

  const promoVariant2 = (
    <>
      <div className="popup__image-container">
        {data.titleAboveImg && <h3 className="popup__title popup__title--top">{data.titleAboveImg}</h3>}
        <div className="popup__image">
          <img src={data.img} alt={data.name} />
        </div>
        {data.titleBelowImg && <h3 className="popup__title popup__title--bottom">{data.titleBelowImg}</h3>}
      </div>
      <div className="popup__content">
        {data.title ? <h2>{data.title}</h2> : ""}
        {data.description?.split("\n").map((text, index) => (
          <p key={index}>
            {text}
            <br />
          </p>
        ))}
        {data?.link && <FindOutMoreBtn additionalClass={"popup__button"} goTo={data.link} buttonText={"Register now"} />}
      </div>
    </>
  );

  return (
    <>
      {isOpen && <div className="popup">
        <div className={`popup__inner popup__inner--${variant}`} ref={popupRef}>
          <button className="popup__close-button" onClick={closePopup}></button>
          {variant === "collage" &&
            (biopage ? <a href={`/meet-the-team/${modifiedName}`}>{collageContent}</a> : collageContent)}
          {variant === "promo1" && promoVariant1}
          {variant === "promo2" && promoVariant2}
        </div>
      </div>}
    </>
  );
}
